import { BaseDto } from './base-dto';
import { lineas_Pregunta } from './lineas_Pregunta';

export class cabezera_pregunta extends BaseDto {
    public id_nutricionista !: number;
    public pregunta !: string;
    public itemseleccionado!:number;
    public solo_una_respuesta!:boolean;
    public lineas_PreguntasDtos: lineas_Pregunta[] = [];
} 
