import { store } from '@/store/store';
import { cabezera_pregunta } from '@/shared/dtos/cabezera_pregunta';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { AxiosResponse } from 'axios';
import { ssmMessageService } from '@/shared/services/message-service';

@Module({
    namespaced: true,
    name: 'cabezera_preguntaModule',
    store,
    dynamic: true
})
class cabezera_preguntaModule extends VuexModule {
    public cabezera_preguntas: cabezera_pregunta[] = [];
    public cabezera_pregunta: cabezera_pregunta = new cabezera_pregunta();

    @Action({ commit: 'onGetcabezera_preguntas' })
    public async getcabezera_preguntas() {
        return await ssmHttpService.get(API.cabezera_preguntas);
    }
    @Action({ commit: 'onGetcabezera_preguntas' })
    public async getcabezera_preguntascabezeras() {
        return await ssmHttpService.get(API.cabezera_preguntas + '/cabezeras/');
    }
    @Action({ commit: 'onGetcabezera_preguntas' })
    public async getcabezera_preguntas_no_contestadas(id_paciente: number) {
        return await ssmHttpService.get(API.cabezera_preguntas + '/nocontestadas/' + id_paciente);
    }

    @Action({ commit: 'onGetcabezera_pregunta' })
    public async getcabezera_pregunta(id: any) {
        return await ssmHttpService.get(API.cabezera_preguntas + '/' + id);
    }

    @Action
    public async guardarcabezera_pregunta(cabezera_pregunta: cabezera_pregunta) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.cabezera_preguntas, cabezera_pregunta.toJson());
        //this.getcabezera_preguntas();
    }

    @Action
    public async modificarcabezera_pregunta(cabezera_pregunta: cabezera_pregunta) {
        await ssmHttpService.put(API.cabezera_preguntas, cabezera_pregunta);
        //this.getcabezera_preguntas();
    }

    @Action
    public async eliminarcabezera_pregunta(cabezera_pregunta: cabezera_pregunta) {
        await ssmHttpService.delete(API.cabezera_preguntas + '/' + cabezera_pregunta.id, null, false);
        //this.getcabezera_preguntas();
    }
    @Action
    public async eliminarcabezera_pregunta_id(id: number) {
        await ssmHttpService.delete(API.cabezera_preguntas + '/' + id, null, false, true).catch(x => this.onGetNoAutorized(x));
        this.getcabezera_preguntascabezeras();
    }


    @Mutation
    public onGetNoAutorized(res: AxiosResponse) {
        if (res.status === 405) {
            ssmMessageService.toastinfo("No esta permitida esta acción");
        }
    }

    @Mutation
    public onGetcabezera_preguntas(res: cabezera_pregunta[]) {
        this.cabezera_preguntas = res;
    }

    @Mutation
    public onGetcabezera_pregunta(res: cabezera_pregunta) {
        this.cabezera_pregunta = new cabezera_pregunta(res);
    }

}
export default getModule(cabezera_preguntaModule);