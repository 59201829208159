

















import { Component, Vue, Prop } from "vue-property-decorator";
import QuestionCard from "@/components/Cards/QuestionCard.vue";
import cabezera_preguntaModule from "@/store/modules/cabezera_pregunta-module";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({
  components: { CompHeader: () => import("./CompHeaderLabel.vue"), QuestionCard }
})
export default class CompQuestion extends Vue {
  @Prop() index!: number;
  public id_paciente: number = 0;
  public created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
    cabezera_preguntaModule.getcabezera_preguntas_no_contestadas(
      this.id_paciente
    );
  }
  public get preguntas() {
    return cabezera_preguntaModule.cabezera_preguntas;
  }
}
