




























import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { lineas_Pregunta } from "@/shared/dtos/lineas_Pregunta";
@Component({
  components: {}
})
export default class QuestionCard extends Vue {
  @Prop({ default: "" }) title!: string;
  @Prop({ default: undefined }) items!: lineas_Pregunta[];
  @PropSync("selectitem", { type: Number }) syncedselectitem!: number;
  public get classes() {
    return {
      "v-card--material--has-heading": this.hasHeading
    };
  }
  public get hasHeading() {
    return Boolean(this.$slots.heading || this.title);
  }
}
